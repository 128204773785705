import { expose } from "comlink";
import { convertTo9 } from "./nine-calculator-worker";
import { convertTo4 } from "./four-calculator-worker";
import { calculateFibonacci } from "./fibonacci-calculator-worker";
import { convertToPalindrome } from "./palindrome-calculator-worker";
import { convertTime } from "./time-converter-worker";

expose({
  convertTo9,
  convertTo4,
  calculateFibonacci,
  convertToPalindrome,
  convertTime
});
